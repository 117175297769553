import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const TawkTo = () => (
  <div className="tawkto-footer">
    <TawkMessengerReact propertyId="63d9081d474251287910a319" widgetId="1go3sfdak" />
  </div>
);

export default TawkTo;
