import React from 'react';
import { Logo } from '../../atoms';
import './footer.scss';
import { TawkTo } from '../../molecules';

export const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__row--logo row">
        <div className="col-xs-12">
          <Logo />
        </div>
      </div>
      <div className="footer__row--contact row center-xs start-md middle-lg">
        <div className="contact col-xs-12 col-md-3">
          <p>
            DeepReturn GmbH <br />
            Tel: +41 76 758 96 80 <br />
            <a href="mailto:info@deepreturn.ch" className="link">
              info@deepreturn.ch
            </a>
          </p>
        </div>
      </div>
    </div>
    <TawkTo />
  </footer>
);

export default Footer;
