/* eslint-disable */

import 'flexboxgrid/dist/flexboxgrid.min.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import '../scss/main.scss';
import { Footer, FooterContact, Header } from '../components/organisms';

export class DefaultLayout extends React.Component {
  componentDidMount() {
    if (!document.getElementById('webfontloader')) {
      const wf = document.createElement('script');
      const s = document.scripts[0];
      wf.src = '/webfont-1.6.26.js';
      wf.async = true;
      wf.id = 'webfontloader';
      wf.onload = () => {
        WebFont.load({
          google: {
            families: ['Roboto:300,400,700,900'],
          },
        });
      };

      s.parentNode.insertBefore(wf, s);
    }
  }

  render() {
    const {
      siteTitle,
      siteDescription = 'DeepReturn - Experten für Webapplikationen und KI-Lösungen in Bern. Erfahren Sie mehr über unsere Projekte und unser Team.',
      siteUrl = 'https://www.deepreturn.com', 
    } = this.props;
    const location = this.props.location || {};
    const pathname = location.pathname || '';
    const pageTitle = siteTitle ? `${siteTitle} | DeepReturn` : 'Softwareentwicklung in Bern';
    const fullUrl = this.props.location ? `${siteUrl}${this.props.location.pathname}` : `${siteUrl}`;
    const imageUrl = 'http://deepreturn.com/static/deepreturn-b70d24c7d76c9aefa1c2fe08caa979b9.png';
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "DeepReturn",
      "url": siteUrl,
      "logo": imageUrl,
      "description": siteDescription
    };
    

    return (
      <div>
        <Helmet>
          <html lang="de" />
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE-edge,chrome=1" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <meta name="theme-color" content="#1e324b" />
          <link rel="icon" type="image/png" href="/favicon.png" />
          <meta name="description" content={siteDescription} />
          <title>{pageTitle}</title>
          {/* Open Graph tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={siteDescription} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={fullUrl} />
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={siteDescription} />
          <meta name="twitter:image" content={imageUrl} />
          {/* Canonical tag */}
          <link rel="canonical" href={fullUrl} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <Header />

        <main>{this.props.children}</main>

        {this.props.siteTitle === 'Kontakt' ? <FooterContact /> : <Footer />}
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  location: PropTypes.object.isRequired,
};

DefaultLayout.defaultProps = {
  siteTitle: undefined,
  siteDescription: undefined,
};

export default DefaultLayout;
