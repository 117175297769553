import React, { Component } from 'react';
import './contactForm.scss';

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      formError: false,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    this.setState({ isLoading: true });
    fetch('https://deepconnect.deepreturn.com/submit-form/', {
      method: 'POST',
      body: data,
      headers: {
        Host: 'deepreturn.com',
      },
    })
      .then(() => {
        form.reset();
        this.setState({ formSubmitted: true, isLoading: false });
      })
      .catch(() => {
        this.setState({ formError: true, isLoading: false });
      });
  }

  render() {
    return (
      <div className="contact-form">
        <div className="container">
          <div className="col-xs-12">
            <div className="contact-form__container">
              <div className="contact-form__container--inner">
                <h2 className="contact-form__container--title">Kontaktformular</h2>
                {this.state.formSubmitted ? (
                  <p className="contact-form__container--text">
                    Danke für Ihre Nachricht. Wir werden uns so schnell wie möglich bei Ihnen melden.
                  </p>
                ) : (
                  <p className="contact-form__container--text">
                    Wir freuen uns über Ihre Nachricht. Bitte füllen Sie das Formular aus und wir werden uns so schnell wie
                    möglich bei Ihnen melden.
                  </p>
                )}
                {this.state.formError ? (
                  <p className="contact-form__container--text-error">
                    Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
                  </p>
                ) : null}
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/success"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="contact-form__container--fields">
                    <div className="contact-form__container--field">
                      <label htmlFor="name" className="contact-form__container--label">
                        Name
                      </label>
                      <input type="text" name="name" id="name" className="contact-form__container--input" required />
                    </div>
                    <div className="contact-form__container--field">
                      <label htmlFor="email" className="contact-form__container--label">
                        E-Mail
                      </label>
                      <input type="email" name="email" id="email" className="contact-form__container--input" required />
                    </div>
                    <div className="contact-form__container--field">
                      <label htmlFor="message" className="contact-form__container--label">
                        Nachricht
                      </label>
                      <textarea name="message" id="message" className="contact-form__container--input-message" required />
                    </div>
                  </div>
                  <div className="contact-form__container--submit">
                    {this.state.isLoading ? (
                      <div className="contact-form__container--spinner" />
                    ) : (
                      <button type="submit" className="contact-form__container--button">
                        Absenden
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
