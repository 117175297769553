import React from 'react';
import { Logo } from '../../atoms';
import './footer.scss';

export const FooterContact = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__row--logo row">
        <div className="col-xs-12">
          <Logo />
        </div>
      </div>
      <div className="footer__row--contact row center-xs start-md middle-lg">
        <div className="address col-xs-12 col-md-3">
          <p>
            DeepReturn GmbH
            <br />
            Seftigenstrasse 19 <br />
            CH-3007 Bern
          </p>
        </div>
        <div className="contact col-xs-12 col-md-3">
          <p>
            Tel: +41 76 758 96 80 <br />
            <a href="mailto:info@deepreturn.ch" className="link">
              info@deepreturn.ch
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default FooterContact;
