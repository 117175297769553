import React from 'react';
import { Link } from 'gatsby';
import logo from './deepreturn.png';
import './logo.scss';

export const Logo = () => (
  <Link to="/" className="logo">
    <img src={logo} alt="deepreturn" />
  </Link>
);

export default Logo;
